<template>
  <div v-loading="loading" class="table-area wrapper-trading history-area">
    <div class="table-top">
    <el-row>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading heading-active">Pending Orders</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div v-if="getRoleSlug == 'admin' || getRoleSlug == 'broker'" class="search-wrapper">
          
          <el-input
              placeholder="Search"
              v-model="listQuery.search"
              class="input-with-select"
              style="width:500px;"
            >
              <el-select
                slot="prepend"
                v-model="listQuery.search_type_client"
                placeholder="select"
                clearable
                filterable
                style="width: 150px"
                @change="handleSelectSearchType()"
              >
                <el-option
                  v-for="item in listDataDropdownClients"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch()"
              ></el-button>
            </el-input>


        </div>
        <div v-else-if="getRoleSlug == 'superadmin'" class="search-wrapper">
          <el-input
            placeholder="Search"
            v-model="listQuery.search"
            class="input-with-select"
            style="width:500px;"
          >
            <el-select
              slot="prepend"
              v-model="listQuery.search_type"
              placeholder="select"
              clearable
              filterable
              style="width: 150px"
              @change="handleSelectSearchType()"
            >
              <el-option
                v-for="item in listAdmins"
                :key="item.master"
                :label="item.name"
                :value="item.master"
              />
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleSearch()"
            ></el-button>
          </el-input>
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper">
          <el-button type="primary" v-if="getRoleSlug == 'admin'" @click="handleCreate()">Add</el-button>
        </div>
      </el-col>
    </el-row>
    </div>
    <Table
      tableKey="pendingOrders"
      :tableConfig="config"
      :tableData="listData"
      :tableQuery="listQuery"
      :tableActionVisibility="false"
      :tableActionViewVisibility="false"
      :tableActionDeleteVisibility="true"
      :tableActionEditVisibility="false"
      :tableLoadingStatus.sync="loading"
      :showSummary="false"
      @view="handleViewDetails($event)"
      @delete="handleDelete($event)"
      @pagination="handlePagination()"
    />
    <AddEditDialog
      :dialog-visible.sync="dialogVisible"
      :dialog-type="dialogType"
      :form-data="formData"
      :listDataDropdownClients="listDataDropdownClients"
      :form-errors.sync="formErrors"
      @getChildFormData="handleChildFormData($event)"
      @childClose="handleChildClose()"
    />
  </div>
</template>

<script>
import Table from "@/components/Table/Table.vue";
import AddEditDialog from "@/views/pending-orders/components/AddEditDialog.vue";
import { fetchList, destroy } from "@/api/pending-order";
import getRoleSlug from "@/store/app.js";
import { store } from "@/api/market-watch";
import { mapGetters } from "vuex";
import { fetchAllAdminList } from "@/api/super-admin/admin";
import { fetchClientList } from "@/api/market-watch";

export default {
  name: "ListPendingOrders",
  components: {
    Table,
    AddEditDialog,
  },
  data() {
    return {
      dialogVisible: false,
      listDataDropdownClients: [],
      dialogType: "create",
      loading: false,
      formData: {},
      formErrors: [],
      config: [
        {
          label: "Type",
          prop: "type",
          width: "",
          className: "blueFont",
        },
        {
          label: "User ID",
          prop: "user_name",
          width: "",
          popover: true,
          className: "blueFont",
        },
        {
          label: "Trade",
          prop: "stock_name",
          width: "320",
          className: "blueFont",
        },
        {
          label: "Rate",
          prop: "price",
          width: "",
          className: "blueFont",
        },
        {
          label: "Lots",
          prop: "lot_and_unit",
          width: "",
          className: "blueFont",
        },
        {
          label: "Status",
          prop: "status_text",
          width: "",
          className: "blueFont",
        },
      ],
      listData: {
      },
      listAdmins: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        search_type: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
      },
      data: {},
    };
  },
  computed: {
    ...mapGetters(["getRoleSlug"]),
  },
  created() {
    if(getRoleSlug.state.roleSlug == 'superadmin') {
      this.getAdminList();
    }else if(getRoleSlug.state.roleSlug == 'admin' || getRoleSlug.state.roleSlug == 'broker'){
      this.getClientList();
    }
    this.getList();
  },
  methods: {
    handleViewDetails(data) {
      console.log(data);
      // return this.$router.push("/admin/dashboard/broker/" + data.id + "&name=" + data.name);
      return this.$router.push(
        "/" + getRoleSlug.state.roleSlug + "/pending-orders/" + data.order_id
      );
    },
    // //////////////////
    // EMIT METHODS
    // //////////////////
    handleChildFormData(data) {
      if (data.dialog_type === "edit") {
        this.handleUpdate(data);
      } else {
        this.handleStore(data);
      }
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getList();
    },
    handleSelectSearchType() {
      this.handleSearch()
    },
    handleSearch() {
      this.listQuery.page = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      fetchList(this.listQuery).then((response) => {
        this.listData = response.data;
        this.loading = false;
      });
    },
    getAdminList() {
      fetchAllAdminList().then((response) => {
        this.listAdmins  = response.data.data;
      });
    },
    getClientList() {
      this.loading = true;
      fetchClientList().then((response) => {
        this.listDataDropdownClients = response.data.data;
      });
    },
    // //////////////////
    // CREATE
    // //////////////////
    handleCreate() {
      this.formData = {
        // segment: "",
        // trade: "",
        // condition: "",
      };
      this.formErrors = [];
      this.dialogVisible = true;
      this.dialogType = "create";
    },
    handleChildClose() {
      this.dialogVisible = false;
    },
    handleStore(data) {
      this.loading = true;
      console.log(data);
      this.dialogVisible = false;
      store(data)
        .then((response) => {
          if (response.data.success === true) {
            this.dialogVisible = false;
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          } else {
            this.flashError(response.data.message);
            this.formErrors = this.validationErrors(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // //////////////////
    // DELETE
    // //////////////////
    handleDelete(data) {
      console.log(data.order_id);
      this.$confirm(
        "This will permanently delete record. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        destroy(data.order_id).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          }
        });
      });
    },
  },
};
</script>
