<template>
  <div v-loading="loading">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="formData"
        :rules="formRules"
        ref="refForm"
        label-position="top"
        :status-icon="true"
      >
        <el-row :gutter="20">
           <el-col
              v-if="getRoleSlug === 'superadmin'"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                :error="formErrors.admin"
                label="Admin"
                prop="admin"
                :label-width="formLabelWidth"
              >
                <el-select v-model="formData.admin" @change="handleChangeAdmin" placeholder="Select">
                  <el-option
                     v-for="item in listAdmins"
                      :key="item.master"
                      :label="item.name"
                      :value="item.master"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.stock_id"
              label="Stock"
              prop="stock_id"
              :label-width="formLabelWidth"
            >
              <el-select v-model="formData.stock_id" placeholder="Select" filterable clearable>
              <el-option
                v-for="item in listDataDropdownScripts"
                :key="item.stock_id"
                :label="item.name"
                :value="item.stock_id"
              >
              </el-option>
            </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.user_id"
              label="User Id"
              prop="user_id"
              :label-width="formLabelWidth"
            >
              <el-select v-model="formData.user_id" placeholder="Select">
                <el-option
                  v-for="item in listDataDropdownClients"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.price"
              label="Rate"
              prop="price"
              :label-width="formLabelWidth"
            >
              <el-input v-model="formData.price" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.order_type"
              label="Trade"
              prop="order_type"
              :label-width="formLabelWidth"
            >
              <br />
              <el-radio-group v-model="formData.order_type">
                <el-radio label="sell">SELL</el-radio>
                <el-radio label="buy">BUY</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.unit"
              label="Lots"
              prop="unit"
              :label-width="formLabelWidth"
            >
              <el-input v-model="formData.unit" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="getRoleSlug === 'admin' || getRoleSlug === 'broker'" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.transaction_password"
              label="Transaction Password"
              prop="transaction_password"
              :label-width="formLabelWidth"
            >
              <!-- <el-input
                v-model="formData.transaction_password"
                autocomplete="off"
              ></el-input> -->
              <el-input
                    v-model="formData.transaction_password"
                    type="password"
                    autocomplete="off"
                    show-password
                  />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <!-- <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.condition"
              label="Condition"
              prop="condition"
              :label-width="formLabelWidth"
            >
              <br />
              <el-radio-group v-model="formData.condition">
                <el-radio label="above">Above</el-radio>
                <el-radio label="below">Below</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog()">Cancel</el-button>
        <el-button type="info" @click="sendChildFormData()">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { requiredRule, numberRule } from "@/rules/all-rules";
import { fetchClientList } from "@/api/market-watch";
import { mapGetters } from "vuex";
import { fetchAllAdminList, adminClients } from "@/api/super-admin/admin";
import getRoleSlug from "@/store/app.js";
import { fetchAdminAllowedStockList } from "@/api/market-watch";




export default {
  name: "AddEditDialog",
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    listDataDropdownClients: {
      type: Array,
      default: function () {
        return [];
      },
    },
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      formRules: {},
      formLabelWidth: "120px",
      //listDataDropdownClients: {},
      listDataDropdownScripts: {},
      listAdmins: {},
    };
  },
  computed: {
     ...mapGetters(["getRoleSlug"]),
    dialogTitle() {
      return this.dialogType === "create"
        ? "Add Pending Order"
        : "Edit Pending Order";
    },
  },
  created() {

    //this.getAdminList() // open when add for super admin this dialog
    if(getRoleSlug.state.roleSlug == 'admin') {
      //this.getClientList();
      this.getStockList()
    } 
    this.formRules.stock_id = requiredRule("stock_id");
    this.formRules.user_id = requiredRule("user_id");
    this.formRules.order_type = requiredRule("order_type");
    this.formRules.price = numberRule("price", ["required"]);
    this.formRules.unit = requiredRule("unit");
    this.formRules.transaction_password = requiredRule("transaction_password");
  },
  methods: {
    getClientList() {
      fetchClientList().then((response) => {
        this.listDataDropdownClients = response.data.data;
      });
    },
    handleCloseDialog() {
      this.$emit("childClose");
    },
    sendChildFormData() {
      this.loading = true;
      // if (this.formData.transaction_password != this.userInfo.transaction_password) {
      //    this.flashError('Please Enter Correct Transaction Password');
      //    this.formData.transaction_password = null
      // } else {
      //     this.formData.transaction_password;
      // }
      //   console.log(this.formData)
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          // WHEN EDIT MERGE NEW PARAMETERS
          if (this.dialogType === "edit") {
            this.formData = Object.assign(this.formData, {
              dialog_type: this.dialogType,
              by_market: false,
            });
          }
          this.formData = Object.assign(this.formData, {
              by_market: false,
            });
          this.$emit("getChildFormData", this.formData);
          this.loading = false;
        }
      });
    },
    getStockList() {
       let dataPost = {
        adminId: this.formData.admin ? this.formData.admin : null
      }
      fetchAdminAllowedStockList(dataPost).then((response) => {
        this.listDataDropdownScripts = response.data.data.all;
      });
    },
    getAdminList() {
      fetchAllAdminList().then((response) => {
        this.listAdmins  = response.data.data;
      });
    },
    handleChangeAdmin(id) {
      // console.log(id);
      adminClients(id).then((response) => {
        this.listDataDropdownClients  = response.data.data;
      });
      this.getStockList();
    }
  },
};
</script>
